import React, { useState, useEffect } from "react";
import { urlFor, client } from "../client";
import { motion } from "framer-motion";
import "../index.css";
import { ClientInfo } from "../components/ClientInfo";

const About = () => {
  const [abouts, setAbouts] = useState([]);
  const [textAbout, setTextAbout] = useState([]);

  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client.fetch(query).then((data) => {
      setAbouts(data);
    });
  }, []);

  useEffect(() => {
    const query = '*[_type == "texts"]';

    client.fetch(query).then((data) => {
      setTextAbout(data);
    });
  }, []);

  return (
    <div id="about" className="h-full pt-[15vh]">
      <div className="px-[6vw] flex flex-col gap-20 md:gap-10 md:flex-row-reverse">
        {textAbout.map((text) => (
          <div className="flex flex-col gap-5 w-full md:w-1/2 md:h-[70vh] md:justify-center">
            <h3 className="font-bold text-5xl xl:text-7xl text-[#081f46] font-sans">
              My awesome <br />
              <span className="text-secondary">services</span>
            </h3>

            <p className="font-Poppins w-full xl:text-lg md:w-3/4 opacity-70">
              {text.about[0]}
            </p>
            <p className="font-Poppins w-full xl:text-lg md:w-3/4 opacity-70">
              {text.about[1]}
            </p>

            <a
              href="#projects"
              className="flex items-center justify-center w-40 xl:w-52 h-14 xl:h-16 lg:text-lg bg-secondary rounded-xl text-white font-Poppins"
            >
              Look at projects
            </a>
          </div>
        ))}
        <div className="w-full md:w-1/2">
          <div
            id="abouts"
            className=" bg-primary rounded-lg w-full h-[50vh] md:h-[70vh] overflow-scroll lg:overflow-hidden scroll-smooth snap-y snap-mandatory"
          >
            {abouts.map((about, index) => (
              <motion.div
                initial={{ x: -300, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                exit={{ x: -300, opacity: 0 }}
                className="mx-5 my-5 xl:my-4 hover:shadow-md rounded-xl transition ease-in-out hover:scale-[101%] duration-500 snap-center"
                key={about.title + index}
              >
                <div className="rounded-xl w-full justify-center flex flex-col bg-white xl:h-[20.5vh] h-[45.3vh] gap-5 snap-center">
                  <div className="h-full flex flex-col items-center justify-center xl:justify-start xl:px-10 gap-5 xl:gap-14 xl:flex-row">
                    <div className="bg-[#faede8] px-10 py-10 rounded-full flex items-center justify-center">
                      <img
                        src={urlFor(about.imgUrl)}
                        alt={about.title}
                        className="w-20 xl:w-16"
                      />
                    </div>
                    <div className="flex flex-col w-full font-Poppins text-center gap-1 md:px-10 lg:px-20 xl:px-0 xl:w-80 xl:text-left">
                      <p className=" font-bold text-lg">{about.title}</p>
                      <p>{about.description}</p>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
          <div className="w-screen flex justify-center -mt-4 -ml-5 gap-5 absolute md:hidden">
            <button
              className="w-8 h-8 bg-black rounded-full flex justify-center items-center"
              onClick={() =>
                (document.getElementById("abouts").scrollTop -=
                  window.innerHeight * 0.48)
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5 15l7-7 7 7"
                />
              </svg>
            </button>
            <button
              className="w-8 h-8 bg-black rounded-full flex justify-center items-center"
              onClick={() =>
                (document.getElementById("abouts").scrollTop +=
                  window.innerHeight * 0.48)
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className="pt-[20vh] md:pt-[30vh]">
        <ClientInfo />
      </div>
    </div>
  );
};

export default About;
