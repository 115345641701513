import Navbar from "./components/Navbar";
import Header from "./container/Header";
import About from "./container/About";
import Projects from "./container/Projects";
import Contact from "./container/Contact";
import Footer from "./components/Footer";
import Stats from "./components/Stats";

function App() {
  return (
    <div className="overflow-x-hidden w-screen bg-white">
      <Navbar />
      <Header />
      <About />
      <Projects />
      <Stats />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
