import React from "react";
import { DiscordBlack, GithubFooter } from "../assets";

const Footer = () => {
  return (
    <div className="w-screen h-20 bg-primary rounded-t-xl flex items-center justify-center">
      <div className="w-full bg-white mx-[2vw] rounded-xl h-14 shadow-sm flex items-center justify-between px-10">
        <p>
          &copy;2022,{" "}
          <a
            href="https://joerischenk.com"
            target="_blank"
            rel="noreferrer"
            className="font-DMSans hover:text-secondary transition-all duration-300"
          >
            Joeri
          </a>
        </p>
        <div className="hidden md:flex gap-2 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
          </svg>
          <a href="mailto:mojangplays11@gmail.com">mojangplays11@gmail.com</a>
        </div>
        <div className="flex gap-10 items-center">
          <a href="https://github.com/Conutik" target="_blank" rel="noreferrer">
            <img src={GithubFooter} className="w-6 h-6" alt="" />
          </a>
          <a
            href="https://discord.com/users/255327008576241674"
            target="_blank"
            rel="noreferrer"
          >
            <img src={DiscordBlack} className="w-7 h-7" alt="portfolio icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
