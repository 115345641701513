import React from "react";

const Stats = () => {
  return (
    <div id="stats" className="pt-[15vh] h-full w-screen font-DMSans">
      <div className="flex flex-col items-center justify-center gap-10 md:gap-16 py-10 lg:py-20 bg-gradient-to-r from-white lg:from-transparent to-christine-200">
        <div className="w-screen flex justify-center">
          <p className="text-5xl font-bold font-DMSans text-[#081f46]">
            Listening to
          </p>
        </div>
        <div className="w-screen flex flex-col md:flex-row gap-10 items-center justify-evenly px-[6vw]">
          <div className="flex flex-col items-center">
            <h2 className="text-4xl md:text-6xl font-medium text-secondary">
              1,862
            </h2>
            <p className="font-bold text-lg md:text-xl">bots</p>
          </div>
          <div className="flex flex-col items-center">
            <h2 className="text-4xl md:text-6xl font-medium text-secondary">
              1,192,660
            </h2>
            <p className="font-bold text-lg md:text-xl">users</p>
          </div>
          <div className="flex flex-col items-center">
            <h2 className="text-4xl md:text-6xl font-medium text-secondary">
              76,795
            </h2>
            <p className="font-bold text-lg md:text-xl">guilds</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
