import React, { useState, useEffect } from "react";
import { Discord, Email } from "../assets";
import { client } from "../client";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contactInfo, setContactInfo] = useState([]);

  const { name, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: "contact",
      name: name,
      email: email,
      message: message,
    };

    client.create(contact).then(() => {
      setLoading(false);
      setIsFormSubmitted(true);
    });
  };

  useEffect(() => {
    const query = '*[_type == "contactInfo"]';

    client.fetch(query).then((data) => {
      setContactInfo(data);
    });
  }, []);

  return (
    <div id="contact" className="pt-[15vh] pb-[9.5vh] h-full font-DMSans">
      <div className="flex flex-col gap-10">
        <h3 className="font-sans text-5xl font-bold text-center text-[#081f46]">
          Contact Me
        </h3>
        <div className="w-screen flex flex-col px-[6vw] gap-10 md:items-center">
          {contactInfo.map((info) => (
            <div className="flex flex-col lg:flex gap-5 md:flex-row md:w-2/3">
              <a
                href="https://discord.com/users/255327008576241674"
                target="_blank
                rel="
                noreferrer
                className="py-3 lg:py-4 w-full rounded-xl bg-primary flex gap-5 items-center justify-start px-10"
              >
                <img src={Discord} alt="phone icon" className="w-10 h-10" />
                <p className="opacity-70 text-sm lg:text-lg text-[#6b7688]">
                  {info.discord}
                </p>
              </a>
              <a
                href={`mailto:${info.email}`}
                className="py-3 lg:py-4 w-full rounded-xl bg-[#effaf0] flex gap-5 items-center justify-start px-10"
              >
                <img src={Email} alt="email icon" className="w-10 h-10" />
                <p className="opacity-70 text-sm lg:text-lg text-[#6b7688]">
                  {info.email}
                </p>
              </a>
            </div>
          ))}
          <form action="" className="flex flex-col gap-5 md:w-2/3">
            <input
              type="text"
              required
              placeholder="Your Name"
              name="name"
              value={name}
              onChange={handleChangeInput}
              className="py-4 md:py-5 rounded-lg text-sm px-5 md:text-lg bg-primary text-[#868ece] placeholder:opacity-70 focus:outline-none"
            />
            <input
              type="email"
              required
              placeholder="Your Email"
              name="email"
              value={email}
              onChange={handleChangeInput}
              className="py-4 md:py-5 rounded-lg text-sm px-5 md:text-lg bg-primary text-[#868ece] placeholder:opacity-70 focus:outline-none"
            />
            <textarea
              type="email"
              required
              placeholder="Your Message"
              name="message"
              value={message}
              onChange={handleChangeInput}
              className="h-32 md:h-40 rounded-lg py-3 text-sm px-5 md:text-lg bg-primary text-[#868ece] placeholder:opacity-70 focus:outline-none"
            />
          </form>
          <div className="w-full flex items-center justify-center">
            <button
              className="bg-[#313bac] text-white w-2/5 py-4 rounded-lg text-xs md:text-lg md:w-1/5"
              onClick={handleSubmit}
            >
              {loading ? "Sending..." : "Send Message"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
