import React, { useState, useEffect } from "react";
import { Github } from "../assets";
import { client, urlFor } from "../client";
import { motion } from "framer-motion";
import "../index.css";

const Projects = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const query = '*[_type == "projects"]';

    client.fetch(query).then((data) => {
      setProjects(data);
    });
  }, []);

  return (
    <div
      id="projects"
      className="pt-[15vh] h-screen w-screen overflow-x-hidden"
    >
      <div className="flex flex-col w-full justify-center items-center gap-10">
        <div>
          <h3 className="text-5xl font-sans font-bold text-[#081f46]">
            Recent Projects
          </h3>
        </div>
        <div
          id="projectdisplay"
          className="flex flex-col h-[70vh] py-2 items-center w-screen gap-10 overflow-y-scroll snap-y snap-mandatory px-[6vw]"
        >
          {projects.map((project) => (
            <motion.div
              animationDuration={{ x: "300" }}
              whileInView={{ x: [-300, 0] }}
              style={{ transition: "all", animationDuration: "0.3s" }}
              whileHover={{ opacity: 0.7, scale: 0.95 }}
              exit={{ x: -300, opacity: 0 }}
              key={project.title}
              className="lg:w-[70vw] h-full lg:h-[70vh] object-contain rounded-xl opacity-100 md:hover:scale-95 transition-all snap-center relative"
            >
              <div className="absolute w-full h-full flex items-center justify-center md:opacity-0 hover:opacity-100 transition-opacity duration-300 font-Poppins flex-col gap-2">
                <p className="text-2xl font-semibold text-white opacity-70">
                  {project.title}
                </p>
                <p className="text-sm px-[6vw] text-center text-white opacity-50">
                  {project.description}
                </p>
                <div className="flex gap-10 mt-3">
                  {project.codeLink ? (
                    <a
                      href={project.codeLink}
                      target="_blank"
                      rel="noreferrer"
                      className="w-10 h-10 bg-black rounded-full flex items-center justify-center md:opacity-70 md:hover:opacity-100 hover:scale-110 transition-all duration-300 ease-in-out"
                    >
                      <img src={Github} alt="" className="w-6 h-6" />
                    </a>
                  ) : (
                    <></>
                  )}
                  {project.projectLink ? (
                    <a
                      href={project.projectLink}
                      target="_blank"
                      rel="noreferrer"
                      className="w-10 h-10 bg-black rounded-full flex items-center justify-center md:opacity-70 md:hover:opacity-100 hover:scale-110 transition-all duration-300 ease-in-out"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                        />
                      </svg>
                    </a>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <img
                src={urlFor(project.imgUrl)}
                alt=""
                className="w-full h-full object-bottom md:object-bottom object-cover rounded-xl"
              />
            </motion.div>
          ))}
        </div>
      </div>
      <div className="w-screen flex justify-center -mt-5 gap-5 absolute md:hidden">
        <button
          className="w-8 h-8 bg-black rounded-full flex justify-center items-center"
          onClick={() =>
            (document.getElementById("projectdisplay").scrollTop -=
              window.innerHeight * 0.7)
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5 15l7-7 7 7"
            />
          </svg>
        </button>
        <button
          className="w-8 h-8 bg-black rounded-full flex justify-center items-center"
          onClick={() =>
            (document.getElementById("projectdisplay").scrollTop +=
              window.innerHeight * 0.7)
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Projects;
