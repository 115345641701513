import React, { useState, useEffect } from "react";
import { HeaderImage } from "../assets";
import { urlFor, client } from "../client";

const Header = () => {
  const [brands, setBrands] = useState([]);
  const [textHeader, setTextHeader] = useState([]);

  useEffect(() => {
    const query = '*[_type == "brands"]';

    client.fetch(query).then((data) => {
      setBrands(data);
    });
  }, []);

  useEffect(() => {
    const query = '*[_type == "texts"]';

    client.fetch(query).then((data) => {
      setTextHeader(data);
    });
  }, []);

  return (
    <header id="home" className="">
      <div className="h-screen w-screen pt-[20vh] bg-primary flex flex-col md:flex-row md:justify-between">
        <div className="mx-[6vw] font-Poppins h-full flex flex-col justify-start md:justify-center md:ml-[6vw] md:w-1/2">
          <h3 className="text-6xl font-bold xl:text-7xl 2xl:text-8xl">
            Hi! I am
          </h3>
          <h3 className="text-6xl font-bold xl:text-7xl 2xl:text-8xl">
            Conutik
          </h3>
          {textHeader.map((text) => (
            <p className="opacity-70 my-5 xl:my-6 xs:w-2/3 xl:w-2/3 xl:text-lg">
              {text.header}
            </p>
          ))}
          <a
            href="#contact"
            className="w-40 flex items-center justify-center xl:w-52 h-14 xl:h-16 lg:text-lg bg-secondary rounded-xl text-white font-Poppins"
          >
            Hire Me
          </a>
        </div>
        <div className="flex h-1/2 justify-center md:justify-end w-screen md:relative md:h-full xl:w-2/3 xl:justify-center">
          <img
            src={HeaderImage}
            alt=""
            className="object-contain object-bottom flex w-full xl:w-5/6"
          />
        </div>
      </div>
      <div className="w-screen h-[20vh] md:h-[30vh] flex flex-col">
        <div className="h-full flex justify-end relative">
          <div className="w-3/4 md:w-1/2 h-full bg-gradient-to-r from-white lg:from-transparent via-christine-50 to-fuchsia-300"></div>
        </div>

        <div className="w-screen h-[20vh] md:h-[30vh] absolute justify-center flex flex-col gap-3 px-[6vw]">
          <p className="font-semibold font-Poppins text-lg xl:text-xl 2xl:text-2xl">
            Previous Clients
          </p>
          <div className="flex gap-3 lg:gap-10 items-center">
            {brands.map((brand) => (
              <div
                className="flex items-center justify-center bg-white shadow-xl rounded-full"
                key={brand.title}
                style={{
                  width: brand.size,
                  height: brand.size,
                }}
                id="brandicon"
              >
                <img
                  src={urlFor(brand.imgUrl)}
                  alt={brand.name}
                  className=""
                  style={{
                    width: "calc(100% / 2)",
                    height: "calc(100% / 2)",
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
