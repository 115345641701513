import React, { useState, useEffect } from "react";
import { AboutImage } from "../assets";
import { client } from "../client";

export const ClientInfo = () => {
  const [textClient, setTextClient] = useState([]);

  useEffect(() => {
    const query = '*[_type == "texts"]';

    client.fetch(query).then((data) => {
      setTextClient(data);
    });
  }, []);

  return (
    <div className="flex flex-col gap-10 overflow-x-hidden">
      <div className="px-[6vw] flex flex-col gap-5">
        <h3 className="text-4xl md:text-6xl font-bold font-sans text-[#081f46]">
          Clients always get
          <br />
          exceptional work
          <br />
          from me
        </h3>
        {textClient.map((text) => (
          <p className="opacity-70 md:w-1/2 md:text-lg">{text.client}</p>
        ))}
      </div>
      <div className="bg-gradient-to-l from-[#effaf0] w-screen h-[20vh] flex relative">
        <div className="h-full flex flex-col justify-center md:w-1/2 px-[6vw]">
          <ul className="flex flex-col gap-2 font-Poppins font-medium xl:text-lg">
            <li>1. Top Qaulity Work</li>
            <li>2. Commitment</li>
            <li>3. 24 Hours Active</li>
          </ul>
        </div>
      </div>
      <div className="w-full h-[50vh] md:flex md:justify-end items-end absolute">
        <img
          src={AboutImage}
          alt="about image"
          className="md:w-1/2 lg:w-2/5 hidden md:flex"
        />
      </div>
    </div>
  );
};
