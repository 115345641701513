import React, { useState, useEffect } from "react";
import { Close, Hamburger } from "../assets";
import { motion, useViewportScroll, useTransform } from "framer-motion";

const Navbar = () => {
  const [ffLayer, setFfLayer] = useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
  const { scrollYProgress } = useViewportScroll();
  const scaleAnim = useTransform(scrollYProgress, [0, 0.5, 1], [0.885, 1, 1]);
  const scaleNavAnim = useTransform(scrollYProgress, [0, 0.5, 1], [1, 1, 1]);

  const yPosAnim = useTransform(scrollYProgress, [0, 0.5, 1], [30, 0, 0]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  scrollYProgress.onChange((x) => {
    setFfLayer(x > 0.4 ? -1 : 0);
  });

  return (
    <>
      <motion.div
        style={{
          scale: scaleAnim,
          y: yPosAnim,
        }} // py-5 px-5 md:px-20 md:py-10
        className="fixed top-0 w-full overflow-hidden z-10"
      >
        <motion.div
          style={{
            zIndex: ffLayer,
          }}
        >
          <div className="bg-white flex justify-between items-center px-5 md:px-10 rounded-xl shadow-sm h-24">
            <div>
              <h1 className="text-2xl text-opacity-60 font-Poppins font-bold">
                Conutik
              </h1>
            </div>
            <button
              className="md:hidden h-10 w-10 flex"
              onClick={() => setIsOpen(!isOpen)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-11 w-11 text-secondary"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>

            <div className="hidden md:flex">
              <ul className="flex md:gap-10 lg:gap-16 items-center md:text-md lg:text-lg font-Poppins font-medium">
                {["Home", "About", "Projects"].map((item) => (
                  <li className="app__flex p-text" key={`link-${item}`}>
                    <div />
                    <a href={`#${item.toLowerCase()}`}>{item}</a>
                  </li>
                ))}
              </ul>
              <div className="ml-10 lg:ml-16 text-white ">
                <a
                  href="#contact"
                  className="bg-secondary lg:px-10 md:px-5 py-3 rounded-lg font-medium font-Poppins"
                >
                  Contact me
                </a>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
      {isOpen && (
        <motion.div
          animate={{ x: [400, 100] }}
          style={{ scaleNavAnim }}
          onClick={() => setIsOpen(!isOpen)}
          transition={{ duration: 0.85, ease: "easeOut" }}
          className="bg-white h-screen fixed w-4/5 z-50 text-black top-0 overflow-hidden shadow-xl border-l border-opacity-50"
        >
          <div className="relative">
            <button className="absolute top-10 right-20 text-3xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex flex-col p-10">
            <ul className="flex flex-col gap-y-6 pb-6 text-xl font-Poppins">
              {["Home", "About", "Projects"].map((item) => (
                <li key={item}>
                  <a
                    href={`#${item.toLowerCase()}`}
                    onClick={() => setIsOpen(false)}
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
            <a
              href="#contact"
              className="py-3 rounded-md flex items-center justify-center w-52 bg-secondary text-white text-lg font-Poppins"
            >
              Contact Me
            </a>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default Navbar;
